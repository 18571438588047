import React from "react";

import { Box, Button, Text } from "@chakra-ui/react";

import { SectionStatusResponse, Status } from "../types";

export type StatusPillStyling = {
  label: string;
  disabledBackgroundColor?: string;
  disabledBorderColor?: string;
  variant: string;
};

export default function StatusPill({
  status,
  isLoading,
  isDisabled,
  isFullWidth = true,
  stylingOverride,
  marginTop = 4,
}: {
  status: SectionStatusResponse;
  isLoading?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  stylingOverride?: StatusPillStyling;
  marginTop?: number | string;
}) {
  // For backwards compatibility
  if (!status.status && !stylingOverride) {
    return (
      <Box ml="12">
        <Text
          display="inline-block"
          borderRadius="lg"
          px="2"
          py="1"
          fontSize="sm"
          color={status.color}
          backgroundColor={status.backgroundColor}
        >
          {status.label}
        </Text>
      </Box>
    );
  }

  const style: StatusPillStyling =
    stylingOverride ??
    {
      [Status.NOT_STARTED]: {
        variant: "statusPillNotStarted",
        label: "Start",
      },
      [Status.STARTED]: {
        variant: "statusPillIncomplete",
        label: "Continue",
      },
      [Status.SUFFICIENT]: {
        variant: "statusPillReview",
        label: "Edit",
      },
      [Status.PRIMARY_START]: {
        variant: "statusPillRecommended",
        label: "Start",
      },
      [Status.OUTLINE_START]: {
        variant: "statusPillNotStarted",
        label: "Start",
      },
      [Status.GREY_EDIT]: {
        variant: "statusPillReview",
        label: "Edit",
      },
      [Status.ORANGE_IN_PROGRESS]: {
        variant: "statusPillIncomplete",
        label: "Continue",
      },
      [Status.CUSTOM_EXPERT_ASSIST]: {
        variant: "statusPillRecommended",
        label: status.label,
      },
    }[status.status];

  return (
    <Button
      _hover={isDisabled ? { cursor: "default" } : { cursor: "pointer" }}
      _active={{}}
      _disabled={{
        backgroundColor: style.disabledBackgroundColor,
        borderColor: style.disabledBorderColor,
      }}
      variant={style.variant}
      mt={marginTop}
      py={3}
      px={8}
      fontSize="md"
      lineHeight="body"
      fontWeight="semibold"
      rounded="button"
      width={isFullWidth ? "100%" : undefined}
      isLoading={isLoading}
      isDisabled={isDisabled}
      loadingText={style.label}
    >
      {style.label}
    </Button>
  );
}
