import {
  BusinessRuleResult,
  ContentRestriction,
  Disqualification,
  RequiredField,
  SubmissionRuleResult,
} from "../../types";
import { Jurisdiction } from "../types";

import { FYTResponseData } from "../../utils/api";
import { CatalogDBEntry } from "./catalog/types";

export enum ErrorType {
  MALFORMED_JSON = "malformed-json",
  UNKNOWN_ERROR = "unknown-error",
  API_ERROR = "api-error",
}

export enum RequestStateType {
  INITIAL = "INITIAL",
  SUCCESS = "SUCCESS",
  LOADING = "LOADING",
  ERROR = "ERROR",
}

type PreviewBaseState = {
  inputs: string;
  queryParams: string;
};

// This is driven from the BE!
// Provides information about a jurisdiction, for example:
// {
//   name: "us",
//   displayName: "federal"
// }
//
// {
//   name: "ca",
//   displayName: "California"
// }
export type JurisdictionInformation = {
  name: string;
  displayName: string;
};

export type TaxYearInformation = {
  name: string;
  label: string;
};

export type XmlTemplate = {
  namespace: string;
  internalId: string | null;
  path: string;
  type: string;
  sourceType: string;
  dataType: string | null;
  v0Scope: string | null;
  fallbackFormula: string | null;
  notes: string;
  lineNumber: string;
};

export type PreviewInitialState = PreviewBaseState & {
  type: RequestStateType.INITIAL;
};

export type ComputationSummary = {
  xml: string;
  expertReviewRedactedXml?: string;
  businessRules: Array<BusinessRuleResult>;
  businessRulesErrors: Array<string>;
  disqualifications: Array<Disqualification>;
  eFileRequired: Array<RequiredField>;
  uiRequired: Array<RequiredField>;
  schemaValidationErrors: Array<string>;
  inputRestrictions: Array<ContentRestriction>;
  submissionRules: Array<SubmissionRuleResult>;
  computationErrors: Array<string>;
  preview: InternalsAndOutputsPreview;
  rejectionHandlerSnapshot?: string;
};

export type InternalsAndOutputsPreview = {
  result: string;
  errors: string;
};

export type PreviewSuccessState = PreviewBaseState &
  ComputationSummary & {
    type: RequestStateType.SUCCESS;
    namespaces: Array<string>;
    previewedInputs: string;
  };

export type PreviewLoadingState = PreviewBaseState & {
  type: RequestStateType.LOADING;
  origin: "download" | "preview" | "createUser";
};

export type PreviewErrorState = PreviewBaseState & {
  type: RequestStateType.ERROR;
  errorType: ErrorType;
  errorMessage?: string;
  errorBacktrace?: string[];
};

export type PreviewState =
  | PreviewInitialState
  | PreviewSuccessState
  | PreviewLoadingState
  | PreviewErrorState;

type ScenariosBaseState = {
  inputFilenames: Array<string>;
  inputSchema: Record<string, unknown> | null;
};

export type ScenariosInitialState = ScenariosBaseState & {
  type: RequestStateType.INITIAL;
};

export type ScenariosSuccessState = ScenariosBaseState & {
  type: RequestStateType.SUCCESS;
};

export type ScenariosLoadingState = ScenariosBaseState & {
  type: RequestStateType.LOADING;
};

export type ScenariosErrorState = ScenariosBaseState & {
  type: RequestStateType.ERROR;
  errorType: ErrorType;
  errorMessage?: string;
};

export type ScenariosState =
  | ScenariosInitialState
  | ScenariosSuccessState
  | ScenariosLoadingState
  | ScenariosErrorState;

export type SnapshotLoadingState = {
  type: RequestStateType.LOADING;
  xml: string;
};

export type SnapshotSuccessState = {
  type: RequestStateType.SUCCESS;
  xml: string;
};

export type SnapshotState = SnapshotLoadingState | SnapshotSuccessState;

export type A2aInitialState = {
  type: RequestStateType.INITIAL;
};

export type A2aLoadingState = {
  type: RequestStateType.LOADING;
};

export type A2aSuccessState = {
  type: RequestStateType.SUCCESS;
  response: string;
  xml: string;
};

export type A2aErrorState = {
  type: RequestStateType.ERROR;
  errorType: ErrorType;
  errorMessage?: string;
};

export type A2aSubmissionState =
  | A2aInitialState
  | A2aLoadingState
  | A2aSuccessState
  | A2aErrorState;

export type FTBInitialState = {
  type: RequestStateType.INITIAL;
};

export type FTBLoadingState = {
  type: RequestStateType.LOADING;
};

export type FTBSuccessState = {
  type: RequestStateType.SUCCESS;
  messageId: string;
};

export type FTBErrorState = {
  type: RequestStateType.ERROR;
  errorType: ErrorType;
  errorMessage?: string;
};
export type FTBSubmissionState =
  | FTBInitialState
  | FTBLoadingState
  | FTBSuccessState
  | FTBErrorState;

export enum LocalStorageType {
  COMPUTATION_INPUTS = "computation-inputs",
  UNIT_TEST = "unit-test",
}

export type HistoryItem = ComputationInputs | UnitTest;

export type ComputationInputs = {
  type: LocalStorageType.COMPUTATION_INPUTS;
  date: number;
  inputs: string;
};

export type UnitTest = {
  type: LocalStorageType.UNIT_TEST;
  date: number;
  namespace: string;
  tests: string;
};

export type CatalogueEntry = {
  sourceType?: string;
  dataType?: string;
  internalId?: string;
  namespace?: string;
  xsdPath?: string;
  xsdType?: string;
  includedFormula?: string;
  disqualifiedFormula?: string;
  eFileRequired?: string;
  uiRequired?: string;
  inV0Scope?: string;
  inputRestrictions?: string;
  forceOutputXml?: string;
  formula?: string;
  errors?: unknown[];
};

export enum CatalogueEditType {
  INSERT = "insert",
  UPDATE = "update",
  DELETE = "delete",
}

type CatalogueEditBase = {
  jurisdiction: string;
  csvFilename: string;
  sheetName: string;
  canonicalId: string;
  dbEntry?: CatalogDBEntry;
};

export type CatalogueInsert = CatalogueEditBase & {
  editType: CatalogueEditType.INSERT;
  newEntry: CatalogueEntry;
};

export type CatalogueUpdate = CatalogueEditBase & {
  editType: CatalogueEditType.UPDATE;
  oldEntry: CatalogueEntry;
  newEntry: CatalogueEntry;
};

export type CatalogueDelete = CatalogueEditBase & {
  editType: CatalogueEditType.DELETE;
  oldEntry: CatalogueEntry;
};

export type CatalogueEdit = CatalogueInsert | CatalogueUpdate | CatalogueDelete;

export type HostBankDashboardSummary = {
  hostBanks: HostBankDashboardSummaryItem[];
};

export type HostBankDashboardSummaryItem = {
  id: number;
  name: string;
  brandColorPrimaryOverride: string;
  corporateName: string;
  dataConsentFilename: string;
  dataConsentToUseFilename: string;
  deepLinkUrl: string;
  freeFiling: boolean;
  logoFilename: string;
  slug: string;
  supportEnabled: boolean;
  svixApplicationId?: string;
  talkEnabled: boolean;
  chatEnabled: boolean;
  fullSizeLogo: boolean;
  showNameInEmail: boolean;
  requiresMultiFactorAuthOnLogin: boolean;
  requiresPhoneOrEmailVerificationBeforeSubmission: boolean;
  shouldProvideBankInfo: boolean;
  shouldSendLifecycleMarketingWebhooks: boolean;
  analyticsNameOverride: string;
  expertAssistConfiguration: string;
  enableTaxFilingFeeUpdates: boolean;
  expertAssistBundleType: string;
  expertAssistDefaultFeeDollars: number;
  expertAssistDefaultFeeFormatted: string;
  expertAssistUpfrontOptInLocation: string;
  expertAssistOptInSupportScreen: boolean;
  expertAssistPaymentLocationIfEnrolledViaColumnTax: string;
};

export type SubmissionDashboardFilter = {
  page: number;
  per: number;
  filter_email: string;
  return_id: number;
  fyt_client_id: string;
  filter_uuid: string;
  filter_host_bank_user_identifier: string;
  host_bank: string;
  federal_jurisdiction_filter: SubmissionDashboardJurisdictionFilter;
  state_jurisdiction_filter: SubmissionDashboardJurisdictionFilter;
  sort_column: string;
  sort_order: string;
  state_jurisdiction: string;
  tax_year_name: string;
  fyt: boolean;
  taxSlayer: boolean;
};

type SubmissionDashboardJurisdictionFilter = {
  submission_state: string;
  submission_rule: string;
  acknowledgement_failure?: string;
};

export type SubmissionDashboardSummary = {
  taxReturns: SubmissionDashboardSummaryItem[];
  total: number;
  filters: {
    returnId: { key: string; value: number }[];
    fytClientId: { key: string; value: string }[];
    hostBank: { key: string; value: string }[];
    // TODO(Billy): rename these to be "federal" specific
    submissionState: { key: string; value: string }[];
    submissionRules: { key: string; value: string }[];
    acknowledgementFailures: { key: string; value: string }[];
    caSubmissionRules?: { key: string; value: string }[];
    jurisdictionFilter: { key: string; value: string }[];
    taxYearFilter: string[];
    fyt: boolean;
    taxSlayer: boolean;
  };
};

export type SubmissionDashboardSummaryItem = {
  hostBank: string;
  name: string;
  returnId: number | null;
  fytClientId: string | null;
  userUUID: string;
  hostBankUserIdentifier: string;
  email: string;
  state: string;
  submissionState: string;
  stateSubmissionState?: string;
  failedSubmissionRules: string[];
  failedCaSubmissionRules: string[];
  acknowledgementFailures: string[];
  lastSubmissionTime: string | undefined;
};

export type SubmissionRuleItem = {
  count: number;
  jurisdiction: string;
  namespace: string;
  passesIf: string;
  ruleNumber: string;
  ruleText: string;
  sheetName: string;
  v0Scope: string;
};

export type ManualReviewSubmissionItem = {
  submissionId: string;
  userId: string;
  jurisdiction: string;
  age: number;
  fedStatus: string;
  stateStatus: string;
  submissionRules: string;
  userReviewLink: string;
  reviewer?: string;
  userReviewNotes?: string;
  submissionNotes?: string;
  taxEngine: string;
  xmlMatches: string | undefined;
};

export type ManualReviewCount = {
  jurisdiction: string;
  count: number;
  targetCount: number;
};

// for TA tool test submissions
export type AdminSubmissionItem = {
  id: string;
  jurisdiction: string;
  submissionId: string;
  submissionXml: string;
  acknowledgmentJson?: string;
  createdAt: string;
  updatedAt: string;
};

export enum SubmissionState {
  NOT_SUBMITTED = "not_submitted",
  IN_REVIEW = "in_review",
  PENDING_SUBMISSION = "pending_submission",
  IN_SUBMISSION = "in_submission",
  SUBMITTED = "submitted",
  EXCEPTION = "exception",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  RETRYABLE = "retryable",
  REJECTED_NON_RETRYABLE = "rejected_non_retryable",
}

export type ReviewQueueObject = {
  returnId: number;
  dashboardUrl: string;
  hours: number;
};

export type SubmissionReviewSummary = {
  // At the moment, there's just one json shared amongst the jurisdictions
  json: string;
  federalJurisdictionReviewSummary: JurisdictionReviewSummary;
  stateJurisdictionReviewSummaries: JurisdictionReviewSummary[];
};

// A "User" has both information on the current state of their calculations as well
// as a summary of their current submission state
export type JurisdictionReviewSummary = {
  jurisdiction: string;
  submissionStatus: SubmissionState;
  isCurrentTaxYear: boolean;
  computedReturnSummary: ComputationSummary;
  taxAgencySubmissionSummaries: TaxAgencySubmissionSummary[];
  latestSubmissionRuleRun?: SubmissionRuleRun;
  efileOpen: boolean;
  reviews: SubmissionRuleRun[];
  latestFytResponse?: FYTResponseData;
};

export type SubmissionRuleRun = {
  reviewed: boolean | undefined;
  accuracy: ReviewOptions | undefined;
  notes: string | undefined;
  reviewer: string | undefined;
  engine: string | undefined;
};

export type UserSubmissionSummary = {
  submissionState: SubmissionState;
  acknowledgement: string | null;
  reviewed: boolean | undefined;
  accuracy: ReviewOptions | undefined;
  notes: string | undefined;
  reviewer: string | undefined;
  jurisdiction: string;
};

export type WebhookMessage = {
  applicationId: string;
  messageId: string;
  createdAt: string;
  payload: {
    type: string;
    timestamp: string;
    userIdentifier: string;
    data: Record<string, unknown>;
  };
};

export type UserSummary = {
  name: string;
  taxYear: string | null;
  email: string;
  hostBank: string;
  hostBankUserIdentifier: string;
  userId?: string;
  uuid: string;
  allUserTaxReturnYears: string[];
  isCurrentTaxYear: boolean;
  userStatus?: string;
  jurisdictionReturnStatuses: Array<{
    jurisdictionName: string;
    jurisdiction: Jurisdiction;
    submissionStatus: SubmissionState;
  }>;
  taxEngine?: string;
  taxSlayerId?: string;
  drakeId?: string;
  zendeskId?: string;
  returnId: number | undefined;
  fytEligible?: boolean;
  fytId?: string;
  notes: string;
  externalApiResponse?: TaxReturnsResponse;
  webhookMessages: WebhookMessage[];
  initializeTaxFilingData: {
    [key: string]: any;
  } | null;
  sharedPartnerDataResponse: {
    [key: string]: any;
  } | null;
  importedUserDataList: {
    [key: string]: any;
  };

  // Expert Assist related
  enrolledInExpertAssist?: boolean;
  expertAssistEnrollmentLabel?: string;
  showSituationNotSupported?: boolean;

  // In non-production environments we include the
  // user's diy_url for easy debugging
  diyUrl?: string;
  totalFeesLabel: string;
  feesPaymentStatus: string;
  uuidsWithSameSsn?: string[];
};

export type TaxReturnsResponse = {
  taxReturns: TaxReturnResponse[];
};

export type TaxReturnResponse = {
  taxYear: number;
  status: string;
  informationDisclosureConsentStatus: string;
  jurisdictions: JurisdictionResponse[];
};

export type JurisdictionResponse = {
  code: string;
  submissionStatus: string;
  refundOrAmountOwed: number;
  refunds: RefundResponse[];
  payment?: PaymentResponse;
};

type RefundResponse = {
  refundMethod: string;
  amount: number;
  accont: BankAccountResponse;
};

type PaymentResponse = {
  amount: number;
  account: BankAccountResponse;
};

type BankAccountResponse = {
  accountType?: string;
  accountNumber: string;
  RoutingNumber: string;
};

export type UserReview = {
  userData: UserSummary;
  adminUser: {
    role: AdminRole;
  };
};

export type AdminRole = Role;

export enum Role {
  INTERNAL = "internal",
  SUPPORT = "support",
}

export type TestUser = {
  uuid: string;
  url: string;
  name: string;
  createdAt: string;
};

export type Submissions = {
  id: number;
  irsSubmissionTaxYear2021Id: number;
  status: string;
  createdAt: string;
};

export type ValidationError = {
  fieldValueTxt?: string;
  ruleNum?: string;
  xpathContentTxt?: string;
  errorMessageTxt?: string;
};

export type ValidationErrorGroup = {
  validationErrorList: {
    errorCnt: number;
    validationErrorGrp: ValidationError[];
  };
};

export type TaxAgencySubmissionSummary = {
  jurisdiction: string | undefined;
  acknowledgmentPayload: ValidationErrorGroup | undefined;
  acknowledgmentStatus: string | undefined;
  submissionCreated: string;
  submissionMethod: string | undefined;
  submissionInput: string | undefined;
  submissionXml: string | undefined;
  notes: string | undefined;
  returnAccuracy: string | undefined;
  reviewer: string | undefined;
  submissionRuleFailures: string[] | undefined;
  acknowledgementFailures: string[] | undefined;
  computationEngine: string | undefined;
};

export type SubmissionReviewInitialState = {
  type: RequestStateType.INITIAL;
};

export type SubmissionReviewLoadingState = {
  type: RequestStateType.LOADING;
};

export type SubmissionReviewSuccessState = {
  type: RequestStateType.SUCCESS;
  summary: SubmissionReviewSummary;
};

export type SubmissionReviewErrorState = {
  type: RequestStateType.ERROR;
};

export type SubmissionReviewState =
  | SubmissionReviewInitialState
  | SubmissionReviewLoadingState
  | SubmissionReviewSuccessState
  | SubmissionReviewErrorState;

export enum SubmitButtonState {
  SUBMITTED = "SUBMITTED",
  NOT_SUBMITTED = "NOT_SUBMITTED",
}

// Submission Review options - keep these in sync with
// the enum in models/submission_rule_run.rb
export enum ReviewOptions {
  Approved = "Accurate",
  Inaccurate = "Inaccurate",
  AccurateWithOddity = "Accurate - Oddity found",
  Automated = "Automated",
  NotReviewedForAccuracy = "Not Reviewed For Accuracy",
}

export enum NotReviewableEmailTemplate {
  Unselected = "unselected",
  DoNotSend = "doNotSend",
  Basic = "basic",
}

export type ErrorHandler = {
  ruleIdentifier: string;
  whoAppliesTo?: string;
};

export type ErrorHandlerWithIndex = ErrorHandler & {
  index?: number;
  value?: string;
};
