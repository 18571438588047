import { useMemo } from "react";

import {
  Block,
  BlockType,
  ImageId,
  ModalStep,
  TextBlockTypes,
} from "../../types";

interface Props {
  columnCollectsPayment: boolean;
  hostBankName?: string;
  refreshScreenDetails: () => Promise<void>;
}

// This step view maps to our Expert Assist Success Screen
export const ExpertAssistSuccessStep = ({
  columnCollectsPayment,
  hostBankName,
  refreshScreenDetails,
}: Props): ModalStep => {
  const description = useMemo(
    () =>
      columnCollectsPayment
        ? "You now have unlimited access to tax experts. You’ll pay for Expert Assist when you file."
        : `You now have unlimited access to tax experts. ${hostBankName} will collect your Expert Assist payment once the IRS accepts your return.`,
    [columnCollectsPayment, hostBankName],
  );
  const blocks: Block[] = [
    {
      id: "image",
      type: BlockType.IMAGE,
      imageId: ImageId.EXPERT_ASSIST_SUCCESS,
    },
    {
      id: "title",
      type: BlockType.NEW_TEXT_BLOCK,
      text: "You’re in! \nWelcome to Expert Assist",
      variant: TextBlockTypes.H4,
      fontWeight: "bold",
      fontAlign: "center",
    },
    {
      id: "description",
      type: BlockType.NEW_TEXT_BLOCK,
      text: description,
      variant: TextBlockTypes.DEFAULT,
    },
  ];

  return {
    blocks,
    buttonLabel: "Continue",
    hideBackButton: true,
    onStepComplete: async () => {
      await refreshScreenDetails();
      return true;
    },
    onClose: async () => {
      await refreshScreenDetails();
    },
  };
};
