import React, { useEffect, useState } from "react";

import {
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import {
  ButtonClickCompletedAction,
  ButtonClickCompletedResult,
  ZendeskWidgetOpenedStatus,
  sendEventButtonClick,
  sendEventButtonClickCompleted,
  sendZendeskWidgetOpened,
} from "../api";
import {
  ActionType,
  ButtonClickEventNames,
  HandleSubmitType,
  NavigationChoiceType,
  Resource,
  ResourceType,
  Screen,
} from "../types";

import rollbar from "../../rollbar-utils";
import { openZendesk } from "../../zendesk";
import QuestionIcon from "../icons/QuestionIcon";
import QuestionIconOutlined from "../icons/QuestionIconOutlined";
import ExpertAssistExpertReviewOnlyModalBody from "./ExpertAssistExpertReviewOnlyModalBody";
import ExpertAssistFallbackLandingModalBody from "./ExpertAssistFallbackLandingModalBody";

interface SupportHeaderProps {
  screenId: string;
  onSubmit: HandleSubmitType | undefined;
  resource?: Resource;
  screen: Screen;
  accessToExpertAssistLiveHelp: boolean;
  accessToExpertAssistExpertReview: boolean;
  shouldColumnTaxOfferExpertAssistOnSupportScreen: boolean;
  expertAssistScreenId: string;
  isPrequestionnaireScreen: boolean;
}

const SupportHeader = ({
  screenId,
  onSubmit,
  resource,
  screen,
  accessToExpertAssistLiveHelp,
  accessToExpertAssistExpertReview,
  shouldColumnTaxOfferExpertAssistOnSupportScreen,
  expertAssistScreenId,
  isPrequestionnaireScreen,
}: SupportHeaderProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const landingScreenIdPermissive = "Screens::ExpertAssistScreen";
  const fallbackScreenId = "Screens::FallbackLoadingScreen";

  const [filledIcon, setFilledIcon] = useState<boolean>(false);

  useEffect(() => {
    setFilledIcon(screenId.includes(landingScreenIdPermissive));
  }, [screenId]);

  const handleClick = () => {
    sendEventButtonClick({
      buttonName: ButtonClickEventNames.NAV_BAR_QUESTION_MARK,
      screenId: screenId,
      expertAssistEnrolled: accessToExpertAssistLiveHelp,
    });
    let action,
      result = ButtonClickCompletedResult.FAILURE;
    try {
      if (
        screenId.includes(fallbackScreenId) &&
        (accessToExpertAssistLiveHelp || accessToExpertAssistExpertReview)
      ) {
        // If we are on a fallback screen to TaxSlayer and we have access
        // to Expert Assist, open the modal.
        onOpen();
        result = ButtonClickCompletedResult.SUCCESS;
        action = ButtonClickCompletedAction.OPENED_EXPERT_ASSIST_MODAL;
      } else if (
        (accessToExpertAssistLiveHelp ||
          (shouldColumnTaxOfferExpertAssistOnSupportScreen &&
            !isPrequestionnaireScreen)) &&
        !screenId.includes(landingScreenIdPermissive) &&
        onSubmit
      ) {
        // Navigate to the Expert Assist landing screen if the taxpayer has access to EA Live Help or
        // if we're allowed to offer and opt them in here. Only navigate if we're not already
        // *on* the Expert Assist landing screen.
        onSubmit({
          navigationChoice: expertAssistScreenId,
          navigationChoiceType: NavigationChoiceType.Screen,
          type: ActionType.NAVIGATE,
          resource: resource,
          resourceType: ResourceType.Target,
        });
        result = ButtonClickCompletedResult.SUCCESS;
        action = ButtonClickCompletedAction.NAVIGATED_TO_LANDING_SCREEN;
      } else {
        // If none of the above are true, then try to open up the Zendesk chat widget.
        const timeout = setTimeout(() => {
          if (!document.querySelector(`iframe[title="Messaging window"]`)) {
            sendZendeskWidgetOpened({
              status: ZendeskWidgetOpenedStatus.FAILED,
            });
          }
        }, 4000);

        const callback = () => {
          clearTimeout(timeout);
          sendZendeskWidgetOpened({
            status: ZendeskWidgetOpenedStatus.SUCCEEDED,
          });
        };

        openZendesk(callback);
        result = ButtonClickCompletedResult.SUCCESS;
        action = ButtonClickCompletedAction.OPENED_ZENDESK_WIDGET;
      }
    } catch (e) {
      rollbar.error(`Error in SupportHeader handleClick: ${e}`);
    } finally {
      sendEventButtonClickCompleted({
        buttonName: ButtonClickEventNames.NAV_BAR_QUESTION_MARK,
        result,
        action,
        screenId,
      });
    }
  };
  return (
    // this is the modal shown to fallback users -- users with Expert-Review only see one type of modal,
    // and users with live-help see a different modal
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />

        <ModalContent
          maxH="calc(100% - 24px)" // 24px boundary from https://linear.app/column-tax/issue/COL-6060#comment-91353658
          backgroundColor="background.light"
        >
          <ModalCloseButton />
          <ModalBody p={{ base: 6, md: 4 }}>
            <Flex
              direction="column"
              height="full"
              justifyContent="space-between"
              alignItems="center"
            >
              {accessToExpertAssistLiveHelp ? (
                <ExpertAssistFallbackLandingModalBody
                  screen={screen}
                  screenId={screenId}
                  // TODO: Fill in the name and phone number if possible
                  name=""
                  phone=""
                />
              ) : (
                <ExpertAssistExpertReviewOnlyModalBody screenId={screenId} />
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <IconButton
        key={screenId}
        display="inline-flex"
        w={12}
        h={12}
        isDisabled={screenId.includes(expertAssistScreenId)}
        _disabled={{
          opacity: 1,
          cursor: "not-allowed",
        }}
        background="none"
        variant="unstyled"
        aria-label="support"
        icon={filledIcon ? <QuestionIcon /> : <QuestionIconOutlined />}
        onClick={handleClick}
        _active={{ color: "border.medium" }}
        css
      />
    </>
  );
};

export default SupportHeader;
