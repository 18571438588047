import React from "react";

import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text } from "@chakra-ui/react";

interface Props {
  title: string;
  content: string;
  cta: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}
const ActionBlock: React.FC<Props> = ({ title, content, cta, onClick }) => {
  return (
    <Flex flexDirection="column">
      <Box
        borderColor="success.dark"
        borderWidth="1px"
        bgColor="success.light"
        borderTopRadius="12px"
        borderBottom="0px"
        p={4}
      >
        <Flex direction="row" alignItems="flex-start">
          <Box>
            <CheckCircleIcon w={6} h={6} color="success.dark" />
          </Box>
          <Box flexDirection="column" ml={3}>
            <Text fontWeight="medium">{title}</Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderColor="success.dark"
        borderWidth="1px"
        borderTop="0px"
        borderBottomRadius="12px"
        p={4}
        backgroundColor="white"
      >
        <Text mb={10}>{content}</Text>
        <Box textAlign="center">
          <Button variant="primary" width="full" onClick={onClick}>
            {cta}
          </Button>
        </Box>
      </Box>
    </Flex>
  );
};

export default ActionBlock;
