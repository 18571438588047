import React, {
  MutableRefObject,
  ReactNode,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";

type NavigationContextType = {
  navigationRef: MutableRefObject<boolean>;
  navigationDisabled: boolean;
  enableNavigation: () => void;
  disableNavigation: () => void;
  updateNavigationDisabled: (value: boolean) => void;
};

const NavigationContext = createContext<NavigationContextType>({
  navigationRef: { current: true },
  navigationDisabled: true,
  enableNavigation: () => undefined,
  disableNavigation: () => undefined,
  updateNavigationDisabled: () => undefined,
});

export const NavigationContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  // TODO(sean/roland) I would have preferred to name the state "navigationEnabed" instead of "disabled".
  //  Unfortunately, chakra and our codebase uses isDisabled in various places, and I didn't want to swap the logic until
  // the entire application is using the enabled/disabled logic here.
  const navigationRef = useRef(false);
  const [navigationDisabled, setNavigationDisabled] = useState(false);

  const disableNavigation = () => {
    navigationRef.current = true;
    setNavigationDisabled(true);
  };

  const enableNavigation = () => {
    navigationRef.current = false;
    setNavigationDisabled(false);
  };

  const updateNavigationDisabled = (value: boolean) => {
    navigationRef.current = value;
    setNavigationDisabled(value);
  };

  return (
    <NavigationContext.Provider
      value={{
        navigationRef,
        navigationDisabled,
        enableNavigation,
        disableNavigation,
        updateNavigationDisabled,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavManager = () => {
  return useContext(NavigationContext);
};
