import React from "react";

import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";

import { sendEventButtonClick } from "../api";
import { ButtonClickEventNames } from "../types";

import { openLink } from "../../fileDownloadUtils";
import { convertToExternalBrowserUrl } from "../../utils/nav-utils";
import ExpertReviewImage from "../images/expert_assist_expert_review.png";

// This modal is shown to taxpayers who fallback to TaxSlayer and who are
// enrolled in an Expert Review only version of Expert Assist.
type Props = {
  screenId: string;
};

export const ExpertAssistExpertReviewOnlyModalBody: React.FC<Props> = ({
  screenId,
}) => {
  return (
    <Box
      pt={4}
      display="flex"
      flexDirection="column"
      gap={5}
      width={{ base: "100%", md: "335px" }}
    >
      <Flex flexDir="column" gap={8}>
        <Flex justifyContent="center" alignItems="center" width="100%" pb={4}>
          <Image src={ExpertReviewImage} maxH="110px" width="auto" />
        </Flex>
        <Heading size="lg" textAlign="center">
          Your Expert Review
        </Heading>
      </Flex>
      <Flex flexDir="column" gridGap="8">
        <Text pt={0}>
          When you’re ready to submit, an expert will review your return for
          mistakes and offer tips for next year too.
        </Text>
        <Button
          width="full"
          variant="primary"
          onClick={() => {
            sendEventButtonClick({
              buttonName: ButtonClickEventNames.REQUEST_EXPERT_REVIEW,
              screenId: screenId,
            });
            const calendly =
              "https://calendly.com/expertassist-columntax/scheduled-expert-review-call";
            openLink(convertToExternalBrowserUrl(calendly));
          }}
        >
          Schedule an Expert Review
        </Button>
      </Flex>
    </Box>
  );
};

export default ExpertAssistExpertReviewOnlyModalBody;
