import React, { KeyboardEvent, useEffect, useRef, useState } from "react";

import { Box, Flex, Heading, Hide, Show } from "@chakra-ui/react";

import { getScreen } from "../api";
import {
  ActionType,
  Block,
  BlockType,
  NavigationChoiceType,
  NavigationScreenProps,
  Resource,
  ResourceType,
  ScreenId,
  UploadType,
} from "../types";

import BlockRenderer from "../components/BlockRenderer";
import Breadcrumbs from "../components/Breadcrumbs";
import ContentContainerWrapper from "../components/ContentContainerWrapper";
import ExpertAssistFallbackBanner from "../components/ExpertAssistFallbackBanner";
import ImageBlock from "../components/ImageBlock";
import LoadingBlock from "../components/LoadingBlock";
import NavHeader from "../components/NavHeader";
import ResponsiveContentWrapper from "../components/ResponsiveContentWrapper";
import SubTitle from "../components/SubTitle";

import { delay } from "../../utils/general";
import { useNavManager } from "../navigationContext";

// TODO Tomasz: We should consider sending this out from the backend, e.g. is_home_screen, or is_bucket_screen
const BUCKET_SCREENS = [
  "Screens::InfoScreen",
  "Screens::HomeScreen",
  "Screens::IncomeScreen",
  "Screens::CreditsDeductionsScreen",
  "Screens::CAHomeScreen",
  "Screens::ILHomeScreen",
  "Screens::Income::ScheduleC::SelfEmploymentIncomeMenuScreen",
  "Screens::ExpertAssistScreen",
];

const HOME_SCREEN_ID = "Screens::HomeScreen";
const OFF_SEASON_LANDING_SCREEN_ID = "Screens::OffSeasonLandingScreen";
const FALLBACK_LOADING_SCREEN_ID = "Screens::FallbackLoadingScreen";

declare global {
  interface WindowEventMap {
    keydown: KeyboardEvent<HTMLDivElement>;
  }
}

const NavigationScreen: React.FC<NavigationScreenProps> = (
  props: NavigationScreenProps,
) => {
  const { screen, onSubmit, onBack, supportEnabled, setScreen } = props;

  const [loading, setLoading] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const {
    navigationDisabled,
    updateNavigationDisabled,
    disableNavigation,
    enableNavigation,
  } = useNavManager();

  const handleNext = (
    screenId: ScreenId,
    resource?: Resource | null,
    sectionName?: string,
  ) => {
    // TODO: This should be turned into a more explicit union between screen navigation and section navigation.
    resource = resource || undefined;
    const action = {
      type: ActionType.NAVIGATE,
      resource: resource,
      resourceType:
        resource === undefined && sectionName === undefined
          ? ResourceType.Source
          : ResourceType.Target,
      navigationChoice: sectionName === undefined ? screenId : sectionName,
      navigationChoiceType:
        sectionName === undefined
          ? NavigationChoiceType.Screen
          : NavigationChoiceType.Section,
    };
    // Delay the transition with the loading screen
    // if there is a non-zero value in the screen response.
    if (screen.delay) {
      setLoading(true);
      delay(screen.delay).then(() => {
        onSubmit(action).finally(() => {
          // unset the loading on error and successful transitionoff
          setLoading(false);
          enableNavigation();
        });
      });
    } else {
      onSubmit(action);
    }
  };

  // TODO(marcia): Our onSubmit / handleFileUpload are slightly duplicated
  // between QuestionScreen and NavigationScreen. Untangle these
  const handleFileUpload = async ({
    file,
    uploadType,
  }: {
    file: File;
    uploadType: UploadType;
  }) => {
    await onSubmit({
      type: ActionType.UPLOAD_W2,
      file: file,
      resource: screen.resource,
      uploadType: uploadType,
    });
  };

  useEffect(() => {
    window && window.scrollTo(0, 0);
    ref?.current?.scrollIntoView();
    enableNavigation();
  }, [screen]);

  if (loading) {
    return <LoadingBlock />;
  }
  const {
    bannerItems,
    blocks,
    desktopIcon,
    estimatorBlock,
    forceRenderingOnCard,
    id,
    illustrationId,
    previousBlock,
    renderBack,
    subTitle,
    title,
  } = screen;

  const hasNavChoices = blocks.some(
    (block: Block) => (block.type === BlockType.NAVIGATION_CHOICE) === true,
  );

  // We are discarding the `TYxx::` part from the screen id by splitting it,
  // shifting the resulting array, and finally joining it back to make `screenId`.
  const fullScreenId = id?.split("::");
  fullScreenId.shift();
  const screenId = fullScreenId.join("::");

  const isBucketScreen = BUCKET_SCREENS.includes(screenId);
  const isHomeScreen = screenId === HOME_SCREEN_ID;
  const isFallbackLoadingScreen = screenId === FALLBACK_LOADING_SCREEN_ID;

  let nonCtaBlocks: Block[] | [] = [];
  let ctaBlocks: Block[] | [] = [];
  let numberOfContinueBlocks = 0;

  // Note: there's special logic that drags "continue" blocks to the bottom of the screen
  // This makes it a bit confusing because even if you wanted a continue block below another block
  // on the screen, it will still be placed at the bottom!
  blocks.map((block: Block) => {
    if (
      block.type === BlockType.CONTINUE_BLOCK ||
      block.type === BlockType.SPLASH_SCREEN_CONTINUE_BLOCK ||
      (block.type === BlockType.EXIT_BLOCK &&
        screenId != OFF_SEASON_LANDING_SCREEN_ID) ||
      block.type === BlockType.POLL_AND_LINK ||
      block.type === BlockType.SAVE_AS_PDF
    ) {
      ctaBlocks = [...ctaBlocks, block];
      (block.type === BlockType.CONTINUE_BLOCK ||
        block.type === BlockType.SPLASH_SCREEN_CONTINUE_BLOCK) &&
        numberOfContinueBlocks++;
    } else {
      nonCtaBlocks = [...nonCtaBlocks, block];
    }
  });

  const hasCtaBlocks = ctaBlocks.length > 0;

  const handleKeyboardEvent = (e: KeyboardEvent<HTMLDivElement>) => {
    let nextScreenId: ScreenId | undefined = undefined;

    // (Tomasz) First we determine which CTA block is the default one i.e. `Continue Block`
    // If there is just one `Continue Block` then we assume that one, otherwise we cannot determine the default action.
    // If the continue block has a `screenId` property, we use this for navigating after `Enter` is pressed.
    if (numberOfContinueBlocks === 1) {
      const defaultContinueActionBlock = ctaBlocks.find(
        (block) =>
          block.type === BlockType.CONTINUE_BLOCK ||
          block.type === BlockType.SPLASH_SCREEN_CONTINUE_BLOCK,
      );
      if (
        defaultContinueActionBlock &&
        "screenId" in defaultContinueActionBlock
      ) {
        nextScreenId = defaultContinueActionBlock.screenId as ScreenId;
      }
    }

    if (e.key === "Enter" && nextScreenId) {
      e.preventDefault();
      disableNavigation();
      handleNext(nextScreenId);
    }
  };

  const refreshScreenDetails = async () => {
    const { screen: updatedScreen } = await getScreen(
      screen.id,
      screen.previousScreenId,
    );
    if (setScreen) {
      setScreen(updatedScreen);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardEvent);

    return () => {
      window.removeEventListener("keydown", handleKeyboardEvent);
    };
  });

  function renderContent() {
    return (
      <Flex
        direction="column"
        height={{ base: "full", md: "auto" }}
        minHeight={
          hasCtaBlocks && isBucketScreen
            ? { base: "full", md: undefined }
            : { base: "full", md: "550px" }
        }
        flex={{ base: 1, md: 0 }}
        gridRowGap={8}
      >
        {illustrationId && (
          <Box>
            <ImageBlock imageId={illustrationId} />
          </Box>
        )}
        {title && title.length > 0 && (
          <Box display="flex" flexDirection="column" gridRowGap={4}>
            <Hide above="md">
              <Breadcrumbs screen={screen} onSubmit={onSubmit} />
            </Hide>
            <Heading data-cypress="title" color="text.primary" my={0}>
              {title}
            </Heading>
            {subTitle && <SubTitle text={subTitle} color="text.primary" />}
            {!isHomeScreen && hasNavChoices && (
              <Box
                display={{ base: "none", md: "flex" }}
                marginTop={4}
                paddingTop={4}
                height={0}
                width="full"
                borderTop="1px dashed"
                borderTopColor="border.medium"
              />
            )}
          </Box>
        )}
        <Flex
          flexDirection="column"
          gap={8}
          justifyContent="space-between"
          flex={1}
        >
          {nonCtaBlocks && nonCtaBlocks.length > 0 && (
            <Box
              display="flex"
              gap={isBucketScreen ? 6 : 8}
              flexDirection="column"
            >
              <BlockRenderer
                blocks={nonCtaBlocks}
                screen={screen}
                onSubmit={handleNext}
                onFileUpload={handleFileUpload}
                onBack={onBack}
                navigationDisableSetter={updateNavigationDisabled}
                navigationDisabled={navigationDisabled}
                setScreen={setScreen}
                refreshScreenDetails={refreshScreenDetails}
              />
            </Box>
          )}
          {!isBucketScreen && ctaBlocks ? (
            <Box display="flex" flexDirection="column">
              <Flex
                flexDirection="row"
                flexWrap="wrap-reverse"
                gap={4}
                justifyContent="center"
                alignItems="center"
              >
                <BlockRenderer
                  blocks={ctaBlocks}
                  screen={screen}
                  onSubmit={handleNext}
                  onBack={onBack}
                  navigationDisableSetter={updateNavigationDisabled}
                  navigationDisabled={navigationDisabled}
                  setScreen={setScreen}
                  refreshScreenDetails={refreshScreenDetails}
                />
              </Flex>
            </Box>
          ) : null}
        </Flex>
      </Flex>
    );
  }

  let cardSize: "md" | "tablet" | undefined;

  if (BUCKET_SCREENS.includes(screenId)) {
    cardSize = "tablet";
  }

  // The logic to render the contents on a card (a white rectangle with rounded corners)
  // has gotten quite tricky! There's an opportunity to refactor this for clarity and
  // correctness.
  // As it's currently written... we currently want to render on a block if we're
  // forcing it... or if there's a call-to-action (usually, a Continue button) and
  // no navigation choices (which in turn lead to more screens).
  let shouldRenderOnCard =
    forceRenderingOnCard || (hasCtaBlocks && !hasNavChoices);

  // However, if there are any "binary attachment upload" blocks, we'll want to render
  // directly to the screen. The screen that contains these blocks, perhaps, is
  // its *own* type of screen... but for now lives on a navigation screen for lack of
  // a better home.
  if (
    blocks.some(
      (block: Block) => block.type === BlockType.BINARY_ATTACHMENT_UPLOAD,
    )
  ) {
    shouldRenderOnCard = false;
  }

  if (isFallbackLoadingScreen) {
    return (
      <Flex flexDirection="column" w="full" bg="white" height="100vh">
        {screen.showExpertAssistFallbackBanner && (
          <ExpertAssistFallbackBanner screen={screen} onSubmit={onSubmit} />
        )}
        <Flex w="full" flex={1}>
          {nonCtaBlocks && (
            <BlockRenderer
              blocks={nonCtaBlocks}
              screen={screen}
              onSubmit={handleNext}
              onFileUpload={handleFileUpload}
              onBack={onBack}
              navigationDisableSetter={updateNavigationDisabled}
              navigationDisabled={navigationDisabled}
              setScreen={setScreen}
              refreshScreenDetails={refreshScreenDetails}
            />
          )}
        </Flex>
      </Flex>
    );
  }

  return (
    <Box w="full" flexDirection="column" zIndex={1}>
      <NavHeader
        onBack={onBack}
        onSubmit={onSubmit}
        bannerItems={bannerItems}
        renderBack={renderBack}
        tempScreenId={id}
        previousBlock={previousBlock}
        estimatorBlock={estimatorBlock}
        supportEnabled={supportEnabled}
        showDesktopTest={desktopIcon}
        screen={screen}
      />
      <ResponsiveContentWrapper
        scrollTargetRef={ref}
        size={cardSize}
        allowFullWidthContainer={screen.fullWidthContainer}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={
            hasCtaBlocks
              ? { base: "space-between", md: "flex-start" }
              : "flex-start"
          }
          gap={4}
          flex={{ base: 1, md: 0 }}
        >
          <Show above="md">
            <Breadcrumbs screen={screen} onSubmit={onSubmit} />
          </Show>
          {shouldRenderOnCard ? (
            <ContentContainerWrapper>{renderContent()}</ContentContainerWrapper>
          ) : (
            renderContent()
          )}

          {hasCtaBlocks && isBucketScreen && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              marginTop={{ base: 6, md: 8 }}
            >
              <Box
                display={{ base: "none", md: "flex" }}
                paddingTop={12}
                height={0}
                width="full"
                borderTop="1px dashed"
                borderTopColor="border.medium"
              />
              <BlockRenderer
                blocks={ctaBlocks}
                screen={screen}
                onSubmit={handleNext}
                onBack={onBack}
                navigationDisableSetter={updateNavigationDisabled}
                navigationDisabled={navigationDisabled}
                setScreen={setScreen}
                refreshScreenDetails={refreshScreenDetails}
              />
            </Box>
          )}
        </Box>
      </ResponsiveContentWrapper>
    </Box>
  );
};

export default NavigationScreen;
