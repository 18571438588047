import { Box, Flex, Text } from "@chakra-ui/react";

import { Action, OpenHelpTypes, Screen } from "../types";

import FallbackBannerIcon from "../icons/ExpertAssist/FallbackBannerIcon";
import DesktopHandoffModalBody from "./DesktopHandoffModalBody";
import Modal from "./Modal";
import NavHeaderDesktopIconButton from "./NavHeaderDesktopIconButton";
import SupportHeader from "./SupportHeader";

interface ExpertAssistFallbackBannerProps {
  screen: Screen;
  onSubmit?: (action: Action, requestScreen?: Screen) => Promise<void>;
}

// This banner replaces the NavHeader in TaxSlayer for EA users to enable them to get expert help even in the TS experience
// We wanted to split this out into its own component to avoid any style conflicts with the NavHeader
const ExpertAssistFallbackBanner = ({
  screen,
  onSubmit,
}: ExpertAssistFallbackBannerProps) => {
  const openEventProperties = {
    openKey: "desktop_icon",
    openType: OpenHelpTypes.BLOCK,
    screenId: screen.id,
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      py={{ base: 6, sm: 3 }}
      px={{ base: 5, sm: 5, md: 8 }}
      w="full"
      backgroundColor="white"
      height={{ base: "92px", md: "72px" }}
    >
      <Flex alignItems="center" justifyContent="center" gap={1}>
        <FallbackBannerIcon />
        <Text fontWeight={600} fontSize={16}>
          You're enrolled in Expert Assist
        </Text>
      </Flex>
      <Flex>
        <Box display={{ base: "initial", md: "none" }}>
          <Modal
            noMargins
            openEventProperties={openEventProperties}
            icon={<NavHeaderDesktopIconButton />}
            body={
              <DesktopHandoffModalBody
                screenId={screen.id}
                userEmail={screen.userEmail}
              />
            }
          />
        </Box>
        <Flex>
          <SupportHeader
            screen={screen}
            screenId={screen.id}
            onSubmit={onSubmit}
            resource={screen.resource}
            accessToExpertAssistLiveHelp={!!screen.accessToExpertAssistLiveHelp}
            accessToExpertAssistExpertReview={
              !!screen.accessToExpertAssistExpertReview
            }
            shouldColumnTaxOfferExpertAssistOnSupportScreen={
              !!screen.shouldColumnTaxOfferExpertAssistOnSupportScreen
            }
            expertAssistScreenId={screen.expertAssistScreenId}
            isPrequestionnaireScreen={screen.isPrequestionnaireScreen}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ExpertAssistFallbackBanner;
