import React from "react";

import { Box, Flex, Heading, Text } from "@chakra-ui/react";

import AnimatedCTLogo from "../icons/AnimatedCTLogo";
import ArrowsIcon from "../icons/ArrowsRight";
import BankIcon from "../icons/Bank";
import UserIcon from "../icons/User";

interface Props {
  text?: string;
}

const defaultCatchPhrase = "Say goodbye to tax season stress";

const icon = (
  <Flex direction="row" alignItems="center">
    <Box px={2}>
      <UserIcon />
    </Box>
    <Box px={2}>
      <ArrowsIcon />
    </Box>
    <Box px={2}>
      <AnimatedCTLogo />
    </Box>
    <Box px={2}>
      <ArrowsIcon />
    </Box>
    <Box px={2}>
      <BankIcon />
    </Box>
  </Flex>
);

const LoadingBlock: React.FC<Props> = ({ text }) => {
  const loadingText = text || "Connecting to Column Tax...";
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="full"
      w="full"
      flex={1}
    >
      <Box>{icon}</Box>
      <Box
        // (Tomasz) Arbitrary max width from #design
        maxW="324px"
      >
        <Text mt="12" fontWeight="600" textAlign="center">
          {loadingText}
        </Text>
        <Heading size="xl" mt="4" fontWeight="extrabold" textAlign="center">
          {defaultCatchPhrase}
        </Heading>
      </Box>
    </Flex>
  );
};

export default LoadingBlock;
