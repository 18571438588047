import React from "react";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { SectionTableFooter, TableRow, TableRowWithLinks } from "../types";

import { convertToExternalBrowserUrl } from "../../utils/nav-utils";

interface Props {
  header: string;
  sections: TableRow[][] | TableRowWithLinks[][];
  footer?: SectionTableFooter;
}

const TableSection = ({
  rows,
  isLastSection,
}: {
  rows: TableRow[] | TableRowWithLinks[];
  isLastSection: boolean;
  footer?: SectionTableFooter;
}) => {
  const renderValue = (
    value: string | number | { text: string; url: string },
  ) => {
    if (typeof value === "string" || typeof value === "number") {
      return value;
    } else {
      return (
        <Link
          color="brand.medium"
          href={convertToExternalBrowserUrl(value.url)}
          rel="noreferrer"
          target="_blank"
          textDecoration={"underline"}
        >
          {value.text}
          <ExternalLinkIcon ml="4px" />
        </Link>
      );
    }
  };

  // The CSS override here is because in GENERAL we want the last child to have a bottom border
  // but since the table overall has a border, the very last section shouldn't have a bottom border
  // because it will cause 2 bottom borders to be rendered
  const sectionRows = rows.map((row, i) => (
    <Tr key={`row-${i}-${row.label}`}>
      <Td
        style={
          i === rows.length - 1 && isLastSection ? { borderBottom: "none" } : {}
        }
      >
        {row.label}
      </Td>
      <Td
        style={
          i === rows.length - 1 && isLastSection ? { borderBottom: "none" } : {}
        }
      >
        {renderValue(row.value)}
      </Td>
    </Tr>
  ));

  return <Tbody backgroundColor={"white"}>{sectionRows}</Tbody>;
};

const SectionTableBlock: React.FC<Props> = ({ header, sections, footer }) => {
  const tableSections = sections.map((section, i) => (
    <TableSection
      isLastSection={i === sections.length - 1}
      rows={section}
      key={`section-${i}-${section[0].label}`}
    />
  ));

  const isRefund = footer?.label.includes("Refund");

  return (
    <Box>
      <TableContainer>
        <Table variant="summary">
          <Thead>
            <Tr>
              <Th colSpan={2}>{header}</Th>
            </Tr>
          </Thead>
          {tableSections}
          {footer && (
            <Tbody backgroundColor={"background.light"}>
              <Tr>
                <Td
                  fontSize="xl"
                  paddingBottom={
                    footer.helpText
                      ? { base: "2!important", md: "2!important" }
                      : undefined
                  }
                >
                  {footer.label}
                </Td>
                <Td
                  fontSize="xl"
                  color={isRefund ? "success.dark" : undefined}
                  paddingBottom={
                    footer.helpText
                      ? { base: "2!important", md: "2!important" }
                      : undefined
                  }
                >
                  {footer.value}
                </Td>
              </Tr>
              {footer.helpText && (
                <Tr>
                  <Td
                    colSpan={2}
                    whiteSpace="normal"
                    wordBreak="break-word"
                    paddingTop={{ base: "0!important", md: "0!important" }}
                    paddingLeft={{ base: "4!important", md: "4!important" }}
                  >
                    <Box textAlign="left">{footer.helpText}</Box>
                  </Td>
                </Tr>
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SectionTableBlock;
