import React from "react";
import { Edit } from "react-feather";

import { Box, Button, Flex, Text } from "@chakra-ui/react";

import { formatCurrencyValue } from "../../utils/question-utils";

interface Props {
  tpPriorYearAgi: number;
  priorYear: number;
  onSubmit: (screen: string) => void;
  screen: string;
}

const PyagiBlock: React.FC<Props> = ({
  tpPriorYearAgi,
  priorYear,
  onSubmit,
  screen,
}) => {
  return (
    <Box
      mt={6}
      pb={4}
      border="2px"
      borderRadius="card"
      borderColor="brand.medium"
    >
      <Flex
        p={4}
        borderTopRadius="card"
        direction="row"
        alignItems="flex-start"
        backgroundColor="brand.light"
      >
        <Box color="brand.dark" mr={2}>
          <Edit size={20} />
        </Box>
        <Box>
          <Text fontSize="md" fontWeight="semibold">
            Your {priorYear} AGI
          </Text>
        </Box>
      </Flex>
      <Box p={4}>
        <Text>You may need this number to file your return.</Text>
      </Box>
      <Box p={4}>
        <Text>
          {priorYear} adjusted gross income (AGI):{" "}
          <b>{formatCurrencyValue(tpPriorYearAgi)}</b>
        </Text>
      </Box>
      <Box p={4}>
        <Button
          width="100%"
          variant="outline"
          size="lg"
          onClick={() => {
            onSubmit(screen);
          }}
        >
          View your return(s)
        </Button>
      </Box>
    </Box>
  );
};

export default PyagiBlock;
