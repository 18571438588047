import React, { useState } from "react";

import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import {
  Action,
  ActionType,
  ResourceDetail,
  SectionStatusResponse,
  Status,
} from "../types";
import { Source } from "../types";

import { detailsValuetoString } from "../helpers/TextFormatters";
import TrashCan from "../icons/TrashCan";
import ChoiceOutline from "./ChoiceOutline";
import ManageDeleteModal from "./ManageDeleteModal";
import StatusPill from "./StatusPill";

function ResourceItem({
  heading,
  subHeading,
  color,
  fontWeight = "500",
  onSubmit,
  resource,
  deleteButtonEnabled,
  resourceName,
  resourceStatus,
  resourceSource,
  details,
  navigationDisabled,
  navigationDisableSetter,
  hostBankName,
  documentType,
}: {
  iconId: string;
  heading: string;
  subHeading?: string;
  color?: string;
  fontWeight?: string;
  onSubmit: (action: Action) => void;
  resource: string;
  deleteButtonEnabled: boolean;
  resourceName: string;
  resourceStatus: undefined | SectionStatusResponse;
  resourceSource?: Source;
  details?: ResourceDetail[] | string;
  navigationDisabled?: boolean;
  navigationDisableSetter?: (state: boolean) => void;
  hostBankName?: string;
  documentType?: string;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const onDelete = () => {
    onSubmit({ type: ActionType.DELETE, resource });
  };

  const onEdit = () => {
    if (!isDisabled && !navigationDisabled) {
      setIsDisabled(true);
      navigationDisableSetter && navigationDisableSetter(true);
      // If we don't get response within 250ms, we turn loaders on
      setTimeout(() => {
        setIsLoading(true);
      }, 250);
      onSubmit({ type: ActionType.EDIT, resource });
    }
  };

  function renderResourceDetailsItem(item: ResourceDetail) {
    const valueString = item.isCurrency
      ? detailsValuetoString(item.value)
      : item.value.toString();

    return (
      <>
        {item.label && item.label + " "}
        {valueString && (
          <Text as="b" fontWeight="semibold">
            {valueString}
          </Text>
        )}
      </>
    );
  }

  function renderDetails(details: ResourceDetail[] | string) {
    if (details.length > 0 && typeof details != "string") {
      return (
        <Box mt={4}>
          {details.map((item: ResourceDetail, index: number) => (
            <Box mt={1} key={index}>
              {renderResourceDetailsItem(item)}
            </Box>
          ))}
        </Box>
      );
    } else {
      return null;
    }
  }

  const defaultStatus = {
    label: "Edit",
    color: "text.primary",
    backgroundColor: "background.dark",
    backgroundColorHover: "border.medium",
    status: Status.SUFFICIENT,
  };

  return (
    <>
      <ManageDeleteModal
        isOpen={isOpen}
        onClose={onClose}
        resourceName={resourceName}
        onDelete={onDelete}
      />

      <Box
        display="flex"
        // This makes the entire card clickable to edit the resource -- otherwise,
        // previously there were awkward gaps surrounding and between the individual
        // targets.
        // There is, however, one set of nested elements that make this tricky
        // -- there is a clickable ... / "More" button which in turn contains a
        // a clickable "Delete" menu item. For both the "More" and "Delete"
        // click targets we stopPropagation so that only our initial target
        // handles the click, instead of letting it bubble up to this handler
        // here.
        // Any additional click targets that are added here would need a similar
        // treatment, and this is why click targets inside other click targets
        // are not ideal.
        // A different, possibly more future-proof, approach would be to
        // re-organize the structure and attentive responsive styling so that
        // there were no nesting of click targets.
        onClick={onEdit}
        rounded="card"
        boxShadow="card"
        backgroundColor="white"
        cursor="pointer"
        _hover={
          navigationDisabled
            ? { cursor: "default" }
            : {
                cursor: "pointer",
                boxShadow: "0 1px 15px 0 #ccc",
              }
        }
      >
        {/* Only show the "Review info we brought in from Partner Bank | last year" text if:
          1. it's partner prefilled, and the taxpayer hasn't yet entered the item
          2. it's prior year prefilled -- once the taxpayer reviews (by saving or deleting) the item,
             then the source will no longer be PRIOR_YEAR.
          TODO(marcia): Instead of passing prefilled/incomplete booleans, pass in
          variation object directly
             */}
        <ChoiceOutline
          prefilled={
            (resourceSource === Source.IMPORTED &&
              resourceStatus?.status === Status.PRIMARY_START) ||
            resourceSource === Source.PRIOR_YEAR ||
            (resourceSource === Source.UPLOADED && documentType !== "W-2")
          }
          incomplete={
            resourceStatus?.status === Status.STARTED ||
            resourceStatus?.status === Status.ORANGE_IN_PROGRESS
          }
          source={resourceSource}
          hostBankName={hostBankName}
        >
          <Flex
            p={{ base: 6, md: 8 }}
            alignItems="center"
            background="white"
            rounded="card"
            w="full"
            direction={{ base: "column", md: "row" }}
            justifyContent={{ base: "flex-start", md: "space-between" }}
          >
            <Link width="full" _hover={{ textDecoration: "none" }}>
              <Flex alignItems="center" w="full">
                <Box alignItems="left" width="full">
                  <Flex
                    justifyContent={{ base: "space-between", md: "flex-start" }}
                  >
                    <Heading
                      size="lg"
                      alignItems="left"
                      color={color}
                      width={{ base: "100%", md: "auto" }}
                    >
                      {heading}
                    </Heading>
                  </Flex>

                  {subHeading && (
                    <Text
                      align="left"
                      color="text.secondary"
                      fontWeight={fontWeight}
                      fontSize="md"
                      width="100%"
                    >
                      {subHeading}
                    </Text>
                  )}

                  {details && renderDetails(details)}
                </Box>
              </Flex>
            </Link>
            <Flex
              width="full"
              display="flex"
              direction="row"
              alignItems="flex-end"
              justifyContent={{ base: "flex-start", md: "flex-end" }}
              marginTop={{ base: 4, md: 0 }}
            >
              <Link
                width={{ base: "full", navmenu: "160px" }}
                _hover={{ textDecoration: "none" }}
              >
                {resourceStatus ? (
                  <StatusPill
                    status={resourceStatus}
                    isFullWidth={true}
                    marginTop={0}
                    isLoading={isLoading}
                    isDisabled={isDisabled || navigationDisabled}
                  />
                ) : (
                  <StatusPill
                    status={defaultStatus}
                    isFullWidth={true}
                    marginTop={0}
                    isLoading={isLoading}
                    isDisabled={isDisabled || navigationDisabled}
                  />
                )}
              </Link>

              {deleteButtonEnabled && (
                <Button
                  data-manage-delete
                  variant="gray"
                  ml={4}
                  height={10}
                  pl={5}
                  pr={5}
                  onClick={(event) => {
                    onOpen();
                    event.stopPropagation();
                  }}
                  aria-label={"Delete"}
                  title={"Delete"}
                >
                  <TrashCan />
                </Button>
              )}
            </Flex>
          </Flex>
        </ChoiceOutline>
      </Box>
    </>
  );
}

export default ResourceItem;
