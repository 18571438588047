import { Monitor } from "react-feather";

import { Box, Icon } from "@chakra-ui/react";

export const NavHeaderDesktopIconButton = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      h={12}
      w={12}
      cursor="pointer"
    >
      <Icon as={Monitor} boxSize={6} />
    </Box>
  );
};

export default NavHeaderDesktopIconButton;
