import React from "react";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Flex,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import dayjs from "dayjs";

import {
  MoneyMoveDateLabel,
  SubmissionState,
  SubmissionStatusSummaryBlock as SubmissionStatusSummaryBlockType,
} from "../types";

import rollbar from "../../rollbar-utils";
import { convertToExternalBrowserUrl } from "../../utils/nav-utils";
import { dollarsToUsd } from "../helpers/CurrencyFormatter";

// Format dates as follows: Dec 6, 2022
const DATE_FORMAT_STRING = "MMM D, YYYY";

function labelFromSubmissionState(submissionState: SubmissionState): {
  text: string;
  color: string;
} | void {
  // Must be kept in sync with ReturnTaxYear::SubmissionState
  switch (submissionState) {
    case SubmissionState.NOT_REVIEWABLE:
    case SubmissionState.RETRYABLE:
    case SubmissionState.REJECTED_NON_RETRYABLE:
    case SubmissionState.EXCEPTION:
      return {
        color: "warning.dark",
        text: "Rejected",
      };
    case SubmissionState.NOT_SUBMITTED:
      return {
        color: "text.primary",
        text: "Not submitted",
      };
    // TODO(marcia): After efile opens -- we will want to show "Not submitted" for
    // **state** returns that are waiting for the corresponding federal return to be
    // accepted by the IRS. In order to that, we may need to wire through the fed/state
    // jurisdiction through to this block.
    // See https://linear.app/column-tax/issue/COL-29738/submissionpending-page-showing-fed-state-returns-with-incorrect
    // and https://linear.app/column-tax/issue/COL-20519/%5Brandf%5D-state-pending-while-federal-rejected-confusion
    case SubmissionState.PENDING_SUBMISSION:
    case SubmissionState.IN_SUBMISSION:
    case SubmissionState.IN_REVIEW:
    case SubmissionState.SUBMITTED:
    case SubmissionState.REJECTED:
      // This "rejected" state represents the moment between
      // 1) receiving the acknowledgment-rejection from the IRS, and
      // 2) deciding whether this rejection is considered retryable or
      //    rejected_non_retryable.
      // While we are figuring things out, keep showing "Pending" to the
      // user
      return {
        color: "warning.dark",
        text: "Pending",
      };
    case SubmissionState.ACCEPTED:
      return {
        color: "brand.medium",
        text: "Accepted",
      };
    default:
      rollbar.error(`Unexpected submission state ${submissionState}`);
  }
}

function showEstimatedProcessingTime(submissionState: string) {
  return (
    submissionState === SubmissionState.IN_REVIEW ||
    submissionState === SubmissionState.SUBMITTED ||
    submissionState === SubmissionState.PENDING_SUBMISSION ||
    submissionState === SubmissionState.IN_SUBMISSION
  );
}

function renderRefundOrPaymentMethod(
  refundOrPaymentMethod: string,
  paymentUrl?: string,
) {
  if (paymentUrl) {
    return (
      <Link
        isExternal
        textDecoration={"underline"}
        color="brand.medium"
        href={convertToExternalBrowserUrl(paymentUrl)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {refundOrPaymentMethod}
        <ExternalLinkIcon ml="4px" />
      </Link>
    );
  }

  return <>{refundOrPaymentMethod}</>;
}

function MoneyMoveDateRow({
  date,
  labelEnum,
  refundUrl,
}: {
  date: string;
  labelEnum: string;
  refundUrl?: string;
}) {
  switch (labelEnum) {
    case MoneyMoveDateLabel.ESTIMATED_REFUND_DATE:
      return (
        <Tbody>
          <Tr>
            <Td>Est. refund date</Td>
            <Td>
              {refundUrl && (
                <Link
                  isExternal
                  textDecoration={"underline"}
                  color="brand.medium"
                  href={convertToExternalBrowserUrl(refundUrl)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                  <ExternalLinkIcon ml="4px" />
                </Link>
              )}
            </Td>
          </Tr>
        </Tbody>
      );
    case MoneyMoveDateLabel.PAYMENT_ON_OR_AFTER_DATE:
      return (
        <Tbody>
          <Tr>
            <Td>Payment on or after</Td>
            <Td>{dayjs(date).format(DATE_FORMAT_STRING)}</Td>
          </Tr>
        </Tbody>
      );
    case MoneyMoveDateLabel.PAYMENT_DUE:
      return (
        <Tbody>
          <Tr>
            <Td>Payment due by</Td>
            <Td>{dayjs(date).format(DATE_FORMAT_STRING)}</Td>
          </Tr>
        </Tbody>
      );
    default:
      return <></>;
  }
}
const SubmissionStatusSummaryBlock: React.FC<
  SubmissionStatusSummaryBlockType
> = ({
  title,
  submissionState,
  datetime,
  datetimeLabel,
  estProcessingTime,
  refundOrAmountOwed,
  refundOrAmountOwedLabel,
  refundOrPaymentMethod,
  refundOrPaymentMethodLabel,
  moneyMoveDate,
  moneyMoveDateLabelEnum,
  failureTitles,
  refundUrl,
  paymentUrl,
  submissionId,
}) => {
  const label = labelFromSubmissionState(submissionState);

  return (
    <Flex flexDirection="column">
      <Table variant="status">
        <Thead>
          <Tr>
            {/* For backwards compatibility, fall back to Federal return. Then when
            # we are always sending title -- remove the fallback */}
            <Th colSpan={2}>{title || "Federal return"}</Th>
          </Tr>
        </Thead>
        {(label?.color || label?.text) && (
          <Tbody>
            <Tr>
              <Td>E-file status</Td>
              <Td color={label.color}>{label.text}</Td>
            </Tr>
          </Tbody>
        )}
        {failureTitles && failureTitles.length > 0 && (
          <Tbody>
            <Tr>
              <Td verticalAlign="baseline">Rejection Reasons</Td>
              <Td>
                {failureTitles.map((failTitle, idx) => (
                  <Text mb={1} key={idx} fontWeight="semibold">
                    {failTitle}
                  </Text>
                ))}
              </Td>
            </Tr>
          </Tbody>
        )}
        {datetime && (
          <Tbody>
            <Tr>
              <Td>{datetimeLabel}</Td>
              <Td>{dayjs(datetime).format(DATE_FORMAT_STRING)}</Td>
            </Tr>
          </Tbody>
        )}

        {/* TODO(marcia): Remove showEstimatedProcessingTime check here after BE is deployed/stable */}
        {showEstimatedProcessingTime(submissionState) && estProcessingTime && (
          <Tbody>
            <Tr>
              <Td>Est. processing time</Td>
              <Td>{estProcessingTime}</Td>
            </Tr>
          </Tbody>
        )}
        <Tbody>
          <Tr>
            <Td>{refundOrAmountOwedLabel}</Td>
            <Td>{dollarsToUsd(refundOrAmountOwed)}</Td>
          </Tr>
        </Tbody>
        {refundOrPaymentMethod && (
          <Tbody>
            <Tr>
              <Td>{refundOrPaymentMethodLabel}</Td>
              <Td>
                {renderRefundOrPaymentMethod(refundOrPaymentMethod, paymentUrl)}
              </Td>
            </Tr>
          </Tbody>
        )}
        <MoneyMoveDateRow
          date={moneyMoveDate}
          labelEnum={moneyMoveDateLabelEnum}
          refundUrl={refundUrl}
        />
        {submissionId && (
          <Tbody>
            <Tr>
              <Td>Submission ID</Td>
              <Td>{submissionId}</Td>
            </Tr>
          </Tbody>
        )}
      </Table>
    </Flex>
  );
};

export default SubmissionStatusSummaryBlock;
