import React, { useEffect, useRef, useState } from "react";

import { Box } from "@chakra-ui/react";

import { sendEventDrakeHandoff } from "../api";
import { ScreenId } from "../types";

import { onNavigate } from "../../utils/api";
import { delay } from "../../utils/general";
import { forwardCloseMessage } from "../../utils/message-utils";
import { SAMLPayloadSuccess, samlAuthenticate } from "../../utils/saml";
import LoadingBlock from "./LoadingBlock";

interface Props {
  // TODO(marcia): One day fix this typo -- milliseconds
  timeoutMilleseconds: number;
  errorScreenId: ScreenId;
  samlUrl: string;
  onSubmit: (screenId: ScreenId) => void;
  shouldIframe: boolean;
}

// Used to perform SAML based auth and connect to our fallback
function FallbackBlock({
  timeoutMilleseconds,
  errorScreenId,
  onSubmit,
  samlUrl,
  shouldIframe,
}: Props) {
  const [SAMLState, setSAMLState] = useState<null | SAMLPayloadSuccess>(null);

  const formRef = useRef<null | HTMLFormElement>(null);
  const renderTime = useState(performance.now())[0];

  const timedNavigate = async () => {
    await delay(timeoutMilleseconds);
    if (formRef.current === null) {
      onSubmit(errorScreenId);
    }
  };

  useEffect(() => {
    // Navigate to error screen if this takes longer than the configured timeout threshold
    timedNavigate();

    const fetchSamlResponse = () => {
      samlAuthenticate(samlUrl)
        .then((response) => {
          switch (response.status) {
            case "pending": {
              return;
            }
            case "success": {
              setSAMLState(response);
              return;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    // Try getting the SAML response immediately in case it's already available.
    // This happens in the case of returning users.
    fetchSamlResponse();

    // Poll since we're creating a user with our fallback which might take a
    // few seconds
    // TODO(Billy): Consider a maxmium number of polling steps here and
    // implement some sort of error handling
    const interval = setInterval(() => {
      // Only keep trying until we've set the form state successfully
      if (formRef.current === null) {
        fetchSamlResponse();
      }
    }, 3 * 1000);

    return () => clearInterval(interval);
  }, []);

  // Once the SAMLState loads, automatically submit the form to perform the redirect
  useEffect(() => {
    if (formRef.current) {
      const pollDurationSeconds = (performance.now() - renderTime) / 1000.0;
      sendEventDrakeHandoff({
        pollDurationSeconds,
        callback: () => formRef.current?.submit(),
      });
    }
  }, [SAMLState]);

  useEffect(() => {
    if (shouldIframe) {
      window.addEventListener("message", forwardCloseMessage);
    }

    return () => {
      if (shouldIframe) {
        window.removeEventListener("message", forwardCloseMessage);
      }
    };
  }, []);

  if (SAMLState === null) {
    return <LoadingBlock text="Loading Column Tax Classic..." />;
  } else {
    onNavigate(SAMLState.acsUrl);

    const conditionalIframeProps = shouldIframe
      ? { target: "fallback-iframe" }
      : {};
    return (
      // Return one encompassing node so that there is not additional grid gap applied
      // between the (invisible form) and the iframe container.
      <Box width="full" height="full">
        <form
          ref={formRef}
          action={SAMLState.acsUrl}
          acceptCharset="UTF-8"
          method="post"
          {...conditionalIframeProps}
        >
          <input
            type="hidden"
            name="SAMLResponse"
            id="SAMLResponse"
            value={SAMLState.samlResponse}
          />
        </form>
        {shouldIframe && (
          <Box w="full" h="full">
            <iframe
              id="fallback-iframe"
              name="fallback-iframe"
              width="100%"
              height="100%"
            ></iframe>
          </Box>
        )}
      </Box>
    );
  }
}

export default FallbackBlock;
